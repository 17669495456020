export class ServiceAdapter {
  constructor(service) {
    this.service = service;
  }

  async getOffers() {
    throw new Error(
      `${this.constructor.name} has not implemented method getOffers`,
    );
  }
}
