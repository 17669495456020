export const addExceptionModelsToChannelData = () => {
  const exceptionModelsEl = document.querySelector('[data-exception-models]');
  const exceptionModelsLabel = exceptionModelsEl.dataset.exceptionModels;
  const exceptionModels = exceptionModelsLabel
    ? exceptionModelsLabel.split(';')
    : [];

  window.channelData = {
    ...window.channelData,
    exceptionModels,
  };
};
