/* eslint-disable no-underscore-dangle */
import { Client } from './client';

export class HubService {
  constructor(client, metaTags = {}, options = {}) {
    this.options = {
      fcaApiUrl:
        metaTags.fca_api_url ||
        document.querySelector("meta[name='fca_api_url']").content,
      fcaApiBrand:
        metaTags.fca_api_brand ||
        document.querySelector("meta[name='fca_api_brand']").content,
      ...options,
    };

    this.client =
      client || new Client(`${this.options.fcaApiUrl}/offers/search`);
    this.defaultParams = {
      brand: this.options.fcaApiBrand,
      market: 'pt-BR',
      stats: ['price_full'],
      facets: [
        'category',
        'engine',
        'gear',
        'highlight',
        'dealer_group',
        'regional_list',
        'state_list',
        'city_list',
      ],
      allowQuery: {
        advancedQuery: {
          model_code: [],
          category: [],
          offer_type: ['fca'],
          campaign: [],
          mvs: [],
          model_year: [],
          highlight: [],
          regional: [],
          zone: [],
          dealer_group: [],
          dealer_list: [],
        },
        locationList: [
          {
            countryCode: 'BR',
            stateCode: 'MG',
            originCityCode: '3106200',
            regionalCityCodeList: [],
          },
        ],
      },
      denyQuery: {
        advancedQuery: {
          campaign: [],
          category: [],
          mvs: [],
          model_code: [],
          model_year: [],
          dealer_group: [],
        },
        locationList: [
          {
            countryCode: 'BR',
            stateCode: 'MG',
            originCityCode: '3106200',
            regionalCityCodeList: [],
          },
        ],
      },
      order:
        'REGIONAL_INDIVIDUAL,NATIONAL_INDIVIDUAL,REGIONAL_DIRECT_SALES,NATIONAL_DIRECT_SALES',
    };
  }

  async getOffers(customParams = {}) {
    try {
      const response = await this._postWrapper(
        this.defaultParams,
        customParams,
      );
      return response;
    } catch (error) {
      return [];
    }
  }

  async getOffer(id, customParams = {}) {
    try {
      const response = await this._postWrapper(
        {
          ...this.defaultParams,
          offerId: `${id}`,
        },
        customParams,
      );
      return response;
    } catch (error) {
      return [];
    }
  }

  _postWrapper(defaultParams, customParams) {
    return this.client.post({
      ...defaultParams,
      ...customParams,
    });
  }
}
