/* eslint-disable no-underscore-dangle */

export class FilterHandler {
  apply(filters, baseData = []) {
    const filterHandlers = {
      keyword: this.filterByName,
      'model_category[]': this.filterByCategory,
    };

    const filtersKeys = Object.keys(filters);
    const filterHandlersKeys = Object.keys(filterHandlers);

    const filtered = filtersKeys.reduce((filteredOffers, currentFilterKey) => {
      if (!filterHandlersKeys.includes(currentFilterKey)) return filteredOffers;

      const currentFilterHandler = filterHandlers[currentFilterKey].bind(this);
      const filterParam = filters[currentFilterKey];
      const filterResult = currentFilterHandler(filterParam, filteredOffers);

      return filterResult;
    }, baseData);

    return filtered;
  }

  _filterData(handler, baseData = []) {
    return baseData.filter(handler);
  }

  filterByName(name, baseData = []) {
    const filteredData = this._filterData(
      offer => offer.name.toLowerCase().includes(name.toLowerCase()),
      baseData,
    );

    return filteredData;
  }

  filterByCategory(categories, baseData = []) {
    if (categories.length < 1) return baseData;

    const filteredData = categories.reduce(
      (filteredItems, category) =>
        filteredItems.concat(
          this._filterData(
            offer => offer.category.toLowerCase() === category.toLowerCase(),
            baseData,
          ),
        ),
      [],
    );

    return filteredData;
  }
}
