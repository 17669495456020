/* eslint no-underscore-dangle: ["error", { "allowAfterThis": true }] */
export class OffersCacheHandler {
  constructor(options = {}) {
    this.offersKey = '@autodromo:ddmOffers:data';
    this.owner =
      options.owner ||
      document.querySelector("meta[name='auto_api_channel']").content;
  }

  _save({ key, value, expirationInMinutes }) {
    const now = new Date();
    const expirationTime = now.getTime() + expirationInMinutes * 60 * 1000;

    const item = {
      value,
      owner: this.owner,
      expiry: expirationTime,
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  _get(key) {
    const item = JSON.parse(localStorage.getItem(key));
    if (!item) return null;

    const now = new Date().getTime();
    const isExpired = now > item.expiry;
    const belongsToAnotherChannel = this.owner !== item.owner;

    if (isExpired || belongsToAnotherChannel) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  }

  getOffers() {
    const storedOffers = this._get(this.offersKey);
    if (!storedOffers) return null;

    return storedOffers;
  }

  saveOffers(data) {
    this._save({
      key: this.offersKey,
      value: data,
      expirationInMinutes: 3,
    });
  }
}
