import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FeaturesDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFeatureIndex: 0,
      previousFeatureIndex: null,
    };

    this.featureRotationInterval = null;
  }

  componentDidMount() {
    const { features } = this.props;

    if (features.length > 1) {
      this.featureRotationInterval = setInterval(() => {
        this.setState(prevState => ({
          previousFeatureIndex: prevState.currentFeatureIndex,
          currentFeatureIndex:
            (prevState.currentFeatureIndex + 1) % features.length,
        }));
      }, 3000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.featureRotationInterval);
  }

  render() {
    const { features } = this.props;
    const { currentFeatureIndex, previousFeatureIndex } = this.state;

    if (features.length === 0) return null;

    return (
      <div className="features-display-list">
        {features.map((feature, index) => (
          <h6
            className={`features-display-list__item ${
              index === currentFeatureIndex ? 'active' : ''
            } ${index === previousFeatureIndex ? 'previous' : ''}`}
          >
            {feature}
          </h6>
        ))}
      </div>
    );
  }
}

FeaturesDisplay.propTypes = {
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FeaturesDisplay;
