/* eslint-disable no-underscore-dangle */
import { DDMOffersRepository } from './repository/DDMOffersRepository';
import { DataHandler, OffersCacheHandler } from './utils';
import { setOffers as setStoreOffers } from '../../stores/DDMOffers';

export class DDMOffersListSyncService {
  constructor({
    ddmOffersRepository = new DDMOffersRepository(),
    offersCacheHandler = new OffersCacheHandler(),
    dataHandler = new DataHandler(),
    setOffers = setStoreOffers,
  } = {}) {
    this.ddmOffersRepository = ddmOffersRepository;
    this.offersCacheHandler = offersCacheHandler;
    this.dataHandler = dataHandler;
    this.setOffers = setOffers;
  }

  async execute(fetchOffersServicesList) {
    if (!fetchOffersServicesList) return;

    const offersCached = this.offersCacheHandler.getOffers();
    const data =
      offersCached || (await this._fetchOffersData(fetchOffersServicesList));

    this.ddmOffersRepository.update(data, this.setOffers);
  }

  async _fetchOffersData(fetchServicesList) {
    const listOffersPromises = fetchServicesList.map(fetchService =>
      fetchService.getOffers(),
    );

    const listOffers = await Promise.all(listOffersPromises);
    const mergedOffers = this.dataHandler.mergeData(listOffers);

    this.offersCacheHandler.saveOffers(mergedOffers);

    return mergedOffers;
  }
}
