export class DataHandler {
  mergeData(data) {
    if (!data || data.length === 0) return [];

    const [autodromoData, hubData = [], marketPlaceData = []] = data;
    const maxLength = Math.max(
      autodromoData.length,
      hubData.length,
      marketPlaceData.length,
    );
    const mergedData = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < maxLength; i++) {
      if (i < autodromoData.length) {
        mergedData.push(autodromoData[i]);
      }

      if (hubData && i < hubData.length) {
        mergedData.push(hubData[i]);
      }

      if (marketPlaceData && i < marketPlaceData.length) {
        mergedData.push(marketPlaceData[i]);
      }
    }

    return mergedData;
  }

  extractBaseTypes(data) {
    if (data.length === 0) return [];

    const baseTypes = [...new Set(data.map(offer => offer.base_type))];

    return baseTypes;
  }

  groupByBaseType(offers, baseTypes) {
    if (baseTypes.length === 0) return [];

    const groupedOffers = baseTypes.reduce((acc, baseType) => {
      acc[baseType] = [];
      return acc;
    }, {});

    offers.forEach(offer => {
      groupedOffers[offer.base_type].push(offer);
    });

    return Object.values(groupedOffers);
  }
}
