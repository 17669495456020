import { DDMOffersListSyncService } from '../ddmOffersListSyncService';
import { HubService } from '../data';
import { DDMOffersRepository } from '../repository/DDMOffersRepository';

export const makeDDMOffersListSyncService = () => {
  const ddmNationalOffersTag = document.querySelector(
    'meta[name="has_ddm_national_offers"]',
  );
  const hasNationalOffers =
    ddmNationalOffersTag && ddmNationalOffersTag.content === 'true';

  if (hasNationalOffers) window.hubService = new HubService();
  window.ddmOffersRepository = new DDMOffersRepository();
  const ddmOffersListSyncService = new DDMOffersListSyncService();

  return ddmOffersListSyncService;
};
