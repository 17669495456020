import fetchival from 'fetchival';

export class Client {
  constructor(baseURI) {
    this.baseURI = baseURI;
  }

  post(params) {
    const response = fetchival
      .fetch(this.baseURI, {
        method: 'post',
        header: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      .then(res => res.json());

    return response;
  }
}
