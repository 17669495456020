import { DataHandler } from './dataHandler';

/* eslint-disable no-underscore-dangle */
export class SortHandler {
  constructor() {
    this.dataHandler = new DataHandler();
  }

  apply(sortParams, baseData = []) {
    const sortHandlers = {
      name: this.orderByNameAsc,
      '-name': this.orderByNameDesc,
      price_value: this.orderByPriceAsc,
      '-price_value': this.orderByPriceDesc,
      keyword: keywordParam => this.orderByKeyword(keywordParam),
      default: data => data,
    };

    const isKeywordSort = sortParams.includes('keyword');

    let currentSortHandler;

    if (isKeywordSort) {
      const keyword = sortParams.split('=')[1];
      currentSortHandler = sortHandlers.keyword(keyword);
    } else {
      currentSortHandler = sortHandlers[sortParams] || sortHandlers.default;
    }

    const bindedSortHandler = currentSortHandler.bind(this);

    const orderedData = bindedSortHandler(baseData);

    return orderedData;
  }

  orderByPriceAsc(basedData) {
    return this._orderByPrice('asc', basedData);
  }

  orderByPriceDesc(basedData) {
    return this._orderByPrice('desc', basedData);
  }

  orderByNameAsc(basedData) {
    return this._orderByName('asc', basedData);
  }

  orderByNameDesc(basedData) {
    return this._orderByName('desc', basedData);
  }

  orderByKeyword(keywordParam) {
    return basedData => this._orderByKeyword(keywordParam, basedData);
  }

  _orderByPrice(type = 'asc', basedData = []) {
    const orderedData = basedData;

    if (type === 'asc') {
      orderedData.sort((a, b) => a.price - b.price);
    } else if (type === 'desc') {
      orderedData.sort((a, b) => b.price - a.price);
    }

    return orderedData;
  }

  _orderByName(type = 'asc', basedData = []) {
    if (type === 'asc') {
      return basedData.sort((a, b) => a.name.localeCompare(b.name));
    }

    if (type === 'desc') {
      return basedData.sort((a, b) => b.name.localeCompare(a.name));
    }

    return basedData;
  }

  _orderByKeyword(keyword = '', basedData = []) {
    const sanitizedKeyword = this._sanitizeKeyword(keyword);
    const baseTypes = this.dataHandler.extractBaseTypes(basedData);

    const similarOffers = basedData.filter(offer =>
      this._isSimilar(offer.name, sanitizedKeyword),
    );
    const otherOffers = basedData.filter(
      offer => !this._isSimilar(offer.name, sanitizedKeyword),
    );

    const groupedSimilarOffers = this.dataHandler.groupByBaseType(
      similarOffers,
      baseTypes,
    );
    const mergedSimilarOffers =
      this.dataHandler.mergeData(groupedSimilarOffers);

    const groupedOtherOffers = this.dataHandler.groupByBaseType(
      otherOffers,
      baseTypes,
    );
    const mergedOtherOffers = this.dataHandler.mergeData(groupedOtherOffers);

    return [...mergedSimilarOffers, ...mergedOtherOffers];
  }

  _sanitizeKeyword(keyword) {
    const prefixTerms = ['nova', 'novo'];
    let sanitizedKeyword = keyword.toLowerCase().replace(/\b\d{4}(?=\s*$)/, '');

    prefixTerms.forEach(prefixTerm => {
      sanitizedKeyword = sanitizedKeyword.replace(prefixTerm, '');
    });

    return sanitizedKeyword.trim();
  }

  _isSimilar(name, keyword) {
    const nameWords = name.toLowerCase().split(' ');
    const keywordWords = keyword.toLowerCase().split(' ');

    return keywordWords.some(word => nameWords.includes(word));
  }
}
