import { createStore, createEvent } from 'effector';

export const setOffers = createEvent('update ddm offers');

const DDMOffers = (() => {
  let instance;

  return (
    defaultValue = {
      data: [],
      loading: true,
    },
  ) => {
    if (!instance) {
      instance = createStore(defaultValue);

      instance.on(setOffers, (state, offers) => ({
        ...state,
        data: offers,
        loading: false,
      }));
    }

    return instance;
  };
})();

export default DDMOffers;
