import { FilterHandler, SortHandler } from '../utils';

/* eslint-disable no-underscore-dangle */
export class DDMOffersRepository {
  constructor(
    filterHandler = new FilterHandler(),
    sortHandler = new SortHandler(),
  ) {
    this.store = window.store && window.store.DDMOffers;
    this.filterHandler = filterHandler;
    this.sortHandler = sortHandler;
  }

  getAll() {
    const data = this.store.getState().data;
    return data;
  }

  getWhere(filters) {
    const allOffers = this.getAll();
    const filtered = this.filterHandler.apply(filters, allOffers);

    return filtered;
  }

  getById(id, baseType) {
    const allOffers = this.getAll();
    const filteredData = allOffers.filter(
      offer => offer.id === id && offer.base_type === baseType,
    );

    return filteredData;
  }

  update(offers, setOffers) {
    if (!offers || !setOffers) return;

    const data = offers;
    setOffers(data);
  }

  list({ page = 1, perPage = 12, filters = {}, sort = '' }) {
    const filteredOffers = this.getWhere(filters);
    const orderedOffers = this._order(sort, filteredOffers);
    const { data, totalPages } = this._paginate(page, perPage, orderedOffers);

    return {
      data,
      page,
      perPage,
      totalPages,
    };
  }

  getAllCategories() {
    const offers = this.getAll();
    const categories = [];

    offers.forEach(offer => {
      const { category } = offer;
      if (!category) return;
      const categoryUpper = category.toUpperCase();
      const isNewCategory = !categories.includes(categoryUpper);

      if (isNewCategory) {
        categories.push(categoryUpper);
      }
    });

    return categories;
  }

  _order(sortParam, baseData = []) {
    return this.sortHandler.apply(sortParam, baseData);
  }

  _paginate(page, perPage, baseData = []) {
    const initialIndex = (page - 1) * perPage;
    const finalIndex = initialIndex + perPage;

    const currentOffers = baseData.slice(initialIndex, finalIndex);
    const totalPages = this._getTotalPages(baseData.length, perPage);

    return {
      data: currentOffers,
      totalPages,
    };
  }

  _getTotalPages(total, perPage) {
    return Math.ceil(total / perPage);
  }
}
