export function getFetchServicesList(servicesAdaptersKeys) {
  const servicesKeys = Object.keys(servicesAdaptersKeys);
  const adapters = servicesKeys.map(serviceKey => {
    const service = window[serviceKey];
    if (!service) return null;

    const ServiceAdapter = servicesAdaptersKeys[serviceKey];
    return new ServiceAdapter(service);
  });

  return adapters;
}
